import Preview from "../shared/models/Preview";
import ProjectItem from "../shared/models/ProjectItem";
import {
  EXPENSE_SKILLS,
  LMS_SKILLS,
  MORS_SKILLS,
  RECIPE_SKILLS,
  STOCKFOLIO_SKILLS,
  SAAS_SKILLS,
  PANTRY_SKILLS,
  PROMPT_SKILLS
} from "./skills";

export const REDDIT_ID = "reddit-clone"
export const SAAS_ID = "saas"
export const RECIPE_ID = "recipe"
export const HACKCLUB_ID = "hackclub"
export const HERSEYDEVELOPERS_ID = "herseydevelopers"
export const HSHACKS_ID = "hshacks"
export const STOCKFOLIO_ID = "stockfolio"
export const MORS_ID = "mors"
export const LMS_ID = "lms"
export const EXPENSE_ID = "expense"
export const PANTRY_ID = "pantry"
export const PROMPT_ID = "prompt"

export const PROJECTS = [
  new ProjectItem(
    LMS_ID,
    LMS_ID,
    "GrowthHungry Academy",
    "GrowthHungry Academy",
    "GrowthHungry Academy Learning Management System",
    "GrowthHungry Academy Learning Management System",
    LMS_ID,
    LMS_SKILLS, // Replace with actual skills
    "GrowthHungry",
    "https://www.growthhungry.life/",
    [
      "Created a scalable Learning Management System for GrowthHungry Academy to manage courses, students, and instructors",
    ],
    [
      new Preview("/screenshots/lms/laptop-3.png", "laptop"),
    ],
    [
      new Preview("/screenshots/lms/laptop-1.png", "laptop"),
      new Preview("/screenshots/lms/laptop-2.png", "laptop"),
      new Preview("/screenshots/lms/laptop-3.png", "laptop"),
      new Preview("/screenshots/lms/laptop-4.png", "laptop"),
      new Preview("/screenshots/lms/laptop-5.png", "laptop"),
      new Preview("/screenshots/lms/laptop-6.png", "laptop"),
      new Preview("/screenshots/lms/laptop-7.png", "laptop"),
      new Preview("/screenshots/lms/laptop-8.png", "laptop"),
      new Preview("/screenshots/lms/phone-1.png", "laptop"),
      new Preview("/screenshots/lms/phone-2.png", "laptop"),
      new Preview("/screenshots/lms/phone-3.png", "laptop"),
      new Preview("/screenshots/lms/phone-4.png", "laptop"),
      new Preview("/screenshots/lms/phone-5.png", "laptop"),
      new Preview("/screenshots/lms/phone-6.png", "laptop"),
      new Preview("/screenshots/lms/phone-7.png", "laptop"),
      new Preview("/screenshots/lms/phone-8.png", "laptop"),
      new Preview("/screenshots/lms/phone-9.png", "laptop"),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/GrowthHungry_Academy" // Leave this blank if no GitHub link
  ),
  new ProjectItem(
    MORS_ID,
    MORS_ID,
    "Merch Order Request System",
    "Merch Order Request System",
    "Custom, merch order request system for High School D214",
    "Custom, merch order request system for High School D214",
    MORS_ID,
    MORS_SKILLS, // Replace with actual skills
    "Hersey Developers",
    "https://herseydevelopers.org/",
    [
      "Contributed to the development of the Merch Order Request Website built for the use throughout High School District 214",
    ],
    [
      new Preview("/screenshots/mors/laptop-1.png", "laptop")
    ], // Leave this empty for screenshots
    [
      new Preview("/screenshots/mors/laptop-1.png", "laptop"),
      new Preview("/screenshots/mors/laptop-2.png", "laptop"),
      new Preview("/screenshots/mors/laptop-3.png", "laptop"),
      new Preview("/screenshots/mors/laptop-4.png", "laptop"),
      new Preview("/screenshots/mors/laptop-5.png", "laptop")
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    "https://merchorder-9fb4f.web.app/", // Leave this blank if no web demo link
    "https://github.com/miguelaenlle/merch-order-request-form" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    STOCKFOLIO_ID,
    STOCKFOLIO_ID,
    "ML Finance Management System",
    "ML Finance Management System",
    "Custom, finance management system with ML capabilities",
    "Custom, finance management system with ML capabilities",
    STOCKFOLIO_ID,
    STOCKFOLIO_SKILLS,
    "Stockfolio.ai",
    null,
    [
      "Built a Finance Management tool to enable financial management operations and a Data Regression tool to enable users to predict future revenue based on previous transactions, products, and revenue"
    ],
    [
      new Preview("/screenshots/stockfolio/laptop-1.png", "laptop")
    ],
    [
      new Preview("/screenshots/stockfolio/laptop-1.png", "laptop"),
      new Preview("/screenshots/stockfolio/laptop-2.png", "laptop"),
      new Preview("/screenshots/stockfolio/laptop-3.png", "laptop")
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/AI-Stock-Market-Management" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    PANTRY_ID,
    PANTRY_ID,
    "Inventory Pantry Management System",
    "Inventory Pantry Management System",
    "Inventory Pantry Tracker System with AI capabilities",
    "Inventory Pantry Tracker System system with AI capabilities",
    PANTRY_ID,
    PANTRY_SKILLS,
    "PantryTracker",
    null,
    [
      "Built a comprehensive web application designed to help users manage their pantry inventory and generate recipes based on the ingredients they have"
    ],
    [
      new Preview("/screenshots/pantry/laptop-2.png", "laptop"),
    ],
    [
      new Preview("/screenshots/pantry/phone-1.png", "laptop"),
      new Preview("/screenshots/pantry/phone-2.png", "laptop"),
      new Preview("/screenshots/pantry/laptop-1.png", "laptop"),
      new Preview("/screenshots/pantry/laptop-2.png", "laptop"),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    "https://pantry-tracker-app.vercel.app/", // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/Pantry-tracker" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    PROMPT_ID,
    PROMPT_ID,
    "AI Prompting Platform",
    "AI Prompting Platform",
    "AI Prompting Platform with Chatbot support",
    "AI Prompting Platform with Chatbot support",
    PROMPT_ID,
    PROMPT_SKILLS,
    "AI Prompting Platform",
    null,
    [
      "Built an open-source AI prompting tool for modern world to discover, create, and share creative prompts"
    ],
    [
      (new Preview("/screenshots/prompt/laptop-1.png", "laptop")),
    ],
    [
      (new Preview("/screenshots/prompt/laptop-1.png", "laptop")),
      (new Preview("/screenshots/prompt/laptop-2.png", "laptop")),
      (new Preview("/screenshots/prompt/phone-1.png", "laptop")),
      (new Preview("/screenshots/prompt/phone-2.png", "laptop")),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    "https://getprompted.vercel.app/", // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/prompt-pal" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    SAAS_ID,
    SAAS_ID,
    "AI Flashcards Generator SaaS Platform",
    "AI Flashcards Generator SaaS Platform",
    "AI Flashcards Generator Platform with SaaS capabilities",
    "AI Flashcards Generator Platform with SaaS capabilities",
    SAAS_ID,
    SAAS_SKILLS,
    "AI Flashcards Generator SaaS Platform",
    null,
    [
      "Developed a SaaS platform that generates flashcards using AI to help students study more efficiently"
    ],
    [
      new Preview("/screenshots/saas/laptop-1.png", "laptop"),
    ],
    [
      new Preview("/screenshots/saas/laptop-1.png", "laptop"),
      new Preview("/screenshots/saas/laptop-2.png", "laptop"),
      new Preview("/screenshots/saas/phone-1.png", "laptop"),
      new Preview("/screenshots/saas/phone-2.png", "laptop"),
      new Preview("/screenshots/saas/phone-3.png", "laptop"),
    ], // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    "https://flashcards-saas-pi.vercel.app/", // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/flashcards-saas" // Leave this blank if no GitHub link
  ),

  new ProjectItem(
    EXPENSE_ID,
    EXPENSE_ID,
    "Expense Tracker",
    "Expense Tracker",
    "A mobile app that provides latest news depending on the category chosen",
    "A mobile app that provides latest news depending on the category chosen",
    EXPENSE_ID,
    EXPENSE_SKILLS,
    "Expense Tracker",
    null, //app link
    [
      "EasyNews offers users a personalized news experience by allowing them to select and customize their preferred news categories"
    ],
    [new Preview("/screenshots/expense/phone-1.png", "laptop")],
    [
      new Preview("/screenshots/expense/phone-1.png", "laptop"),
    ],
    [], //videos
    null, //app store link
    null,
    null
  ),

  new ProjectItem(
    RECIPE_ID,
    RECIPE_ID,
    "Recipe Finder",
    "Recipe Finder",
    "A mobile app that provides users thorough recipes.",
    "A mobile app that provides users thorough recipes.",
    RECIPE_ID,
    RECIPE_SKILLS,
    "EasyRecipes",
    null,
    [
      "Developed an Android app that instantly finds easy, healthy, and accessible meals using a user’s available kitchen ingredients"
    ],
    [
      new Preview("/screenshots/recipe/phone-1.png", "laptop"),
    ],
    [
      new Preview("/screenshots/recipe/phone-1.png", "laptop"),
    ],  // Leave this empty for previews
    [], // Leave this empty for videos
    null, // Leave this blank if no app demo link
    null, // Leave this blank if no web demo link
    "https://github.com/ArslanKamchybekov/EasyRecipe" // Leave this blank if no GitHub link
  ),
];
