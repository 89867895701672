const Footer: React.FC<{}> = (props) => {
  return (
    <div>
      <p className="flex flex-col items-center py-10">
        <p className="text-sm">Built by Arslan Kamchybekov</p>
      </p>
    </div>
  );
};
export default Footer;
