/* eslint-disable jsx-a11y/alt-text */
import { REDDIT_ID } from "../../constants/projects";
const SPECIAL_IDS = [REDDIT_ID];

const ImageIcon: React.FC<{ id: string; imageName: string }> = (props) => {
  if (!SPECIAL_IDS.includes(props.id)) {
    return (
      <img className="h-12 rounded-md object-contain" src={require(`/public/icons/apps/${props.imageName}.png`)} />
    );
  } else {
    return <img className="h-12 object-contain" src={require(`/public/icons/apps/${props.imageName}.png`)} />;
  }
};
export default ImageIcon;
