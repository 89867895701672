import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";

ReactGA.initialize("G-DNPPGP50VD");
ReactGA.send({
  hittype: "pageview",
  page: window.location.pathname,
});
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
